.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.container:not(:last-child) {
  margin-bottom: 16px;
}

.container > *:not(:first-child) {
  margin-top: 16px;
}

.title {
  border-bottom: 1px solid #eaecef;
}

@media screen and (max-width: 900px) {
  .title {
    font-size: 24px;
  }
}
