.container {
  position: absolute;
  top: 32px;
  display: flex;
  flex-direction: column;

  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.container > *:not(:first-child) {
  margin-top: 32px;
}

.right {
  right: 32px;
  align-items: flex-end;
}

.left {
  left: 32px;
  align-items: flex-start;
}

.block {
  background-color: white;
  border-radius: 4px;
  pointer-events: auto;
}

.hline {
  width: 80%;
  margin-left: 10%;
  height: 1px;
  background-color: lightgrey;
}

.item > button {
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  width: 44px;
}
