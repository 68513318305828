.vignette {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 200px rgba(0, 0, 0, 0.6) inset;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .vignette {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.6) inset;
  }
}
