.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 2s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 999;
  pointer-events: none;
}

.preloader {
  position: absolute;
  bottom: 32px;
  right: 32px;
}
