.container {
  position: fixed;
  bottom: 8px;
  left: 32px;
  filter: drop-shadow(0px 0px 15px rgba(0 0 0 / 20%));
  color: white;
  font-size: 20px;
  font-weight: 450;
}

@media screen and (max-width: 768px) {
  .container {
    font-size: 12px;
  }
}
