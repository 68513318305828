.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* height: 100vh; */
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(50px);
  z-index: 1;
}

.darkbar {
  width: 100%;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.header {
  justify-content: space-between !important;
  padding-left: 8px;
  padding-right: 8px;
}

.darkbar button {
  background: transparent;
  border: none;
  width: 40px;
  height: 35px;
  margin: 5px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  outline: none;
}

.darkbar button:disabled {
  color: rgba(255, 255, 255, 0.1);
}

.darkbar > div {
  display: inline-flex;
}

.scrollable {
  width: 100%;
  height: auto;
}
