.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
}
