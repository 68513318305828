.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  background-color: white;
  color: dimgray;
  border-radius: 4px;
  pointer-events: auto;
}

.left {
  left: 32px;
}

.visibleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px;
  width: 132px;
}

.visibleContent > *:not(:first-child) {
  margin-top: 16px;
}

.openContentButton {
  padding: 0;
  color: gray;
  min-width: 42px;
  height: 36px;
}
