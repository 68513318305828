.gallery {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  /* padding-top: 10px; */
  padding-bottom: 10px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: block; /* IE and Edge */
  scrollbar-width: block; /* Firefox */
  min-height: 180px; /* must be same height set on json image if no bug div.offsetHeight */ 
}

.gallery > div > div {
  flex-wrap: nowrap !important;
}

.gallery::-webkit-scrollbar {
  display: block;
  height: 7px;
  -webkit-transition: background .3s;
  transition: background .3s;
}

.gallery::-webkit-scrollbar-corner {
  background: #FFF;
}

.gallery::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(230,231,233,1);
}

.gallery::-webkit-scrollbar-track {
  background: #FFF;
}
