.container {
  position: fixed;
  bottom: 8px;
  right: 32px;
  filter: drop-shadow(0px 0px 15px rgba(0 0 0 / 20%));
  opacity: 0.5;
  color: white;
}

@media screen and (max-width: 768px) {
  .container {
    font-size: 12px;
  }
}
