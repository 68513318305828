.modalElement {
  padding: 15px;
  position: absolute;
  background-color: rgb(248, 249, 250);
  width: 100%;
  max-width: 700px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-bottom: 15px;
  border: 1px solid rgb(222, 226, 230);
}

.closeContainer {
  position: absolute;
  top: -26px;
  right: -8px;
  color: gray;
  cursor: pointer;
  padding: 24px;
  z-index: 1;
}

.closeContainer:active {
  background-color: transparent;
}

.content {
  margin-top: 5px;
}
